<template>
    <div :class="['course', isMobile()?'mb-content':'']">
        <div class="list">
            <transition name="line-slide-fade">
                <div class="line" v-if="courseShow"></div>
            </transition>
            <div v-for="(item,index) in hostoryList" :key="item.imgUrl">
                <div class="item first" v-if="index===0">
                    <transition name="span-slide-fade">
                        <span class="year" v-if="courseShow"><b>{{item.year}}</b>年</span>
                    </transition>
                    <transition name="b-slide-fade">
                        <b class="circle" v-if="courseShow"></b>
                    </transition>
                    <transition name="course-slide-fade">
                        <div class="context" v-if="courseShow">
                            <transition name="img-slide-fade">
                                <div class="img">
                                    <img v-if="imgShow" :src="item.imgUrl" alt="">
                                </div>
                            </transition>
                            <p>{{item.title}}</p>
                        </div>
                    </transition>
                </div>
                <!-- <div class="item last" v-else-if="(index+1)===hostoryList.length">
                    <transition name="course-slide-fade">
                        <div class="context"  v-if="courseShow">
                            <transition name="img-slide-fade">
                                <img v-if="imgShow" src="../../../assets/logo.png" alt="">
                            </transition>
                            <p>签订《桂林市临桂区水务一体化PPP项目合作框架协议》</p>
                        </div>
                    </transition>
                    <transition name="b-slide-fade">
                        <b class="circle" v-if="courseShow"></b>
                    </transition>
                    <transition name="span-slide-fade">
                        <span class="year" v-if="courseShow"><b>2015</b>年</span>
                    </transition>
                </div> -->
                <div class="item left" v-else-if="index%2===1">
                    <transition name="course-slide-fade">
                        <div class="context"  v-if="courseShow">
                            <transition name="img-slide-fade">
                                <div class="img">
                                    <img v-if="imgShow" :src="item.imgUrl" alt="">
                                </div>
                            </transition>
                            <p>{{item.title}}</p>
                        </div>
                    </transition>
                    <transition name="b-slide-fade">
                        <b class="circle" v-if="courseShow"></b>
                    </transition>
                    <transition name="span-slide-fade">
                        <span class="year" v-if="courseShow"><b>{{item.year}}</b>年</span>
                    </transition>
                </div>
                <div class="item right" v-else-if="index%2===0">
                    <transition name="span-slide-fade">
                        <span class="year" v-if="courseShow"><b>{{item.year}}</b>年</span>
                    </transition>
                    <transition name="b-slide-fade">
                        <b class="circle" v-if="courseShow"></b>
                    </transition>
                    <transition name="course-slide-fade">
                        <div class="context"  v-if="courseShow">
                            <transition name="img-slide-fade">
                                <div class="img">
                                    <img v-if="imgShow" :src="item.imgUrl" alt="">
                                </div>
                            </transition>
                            <p>{{item.title}}</p>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { getHistory } from '@/api/about'
export default {
    setup() {
        const courseShow = ref(false)
        const imgShow = ref(false)
        const hostoryList = ref([])

        // 判断浏览器函数
        const isMobile = () => {
            if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                return true; // 移动端
            }else{
                return false; // PC端
            }
        }

        const changeCourse = () => {
            setTimeout(() => {
                courseShow.value = true
            }, 50);
            setTimeout(() => {
                imgShow.value = true
            }, 55);
        }
        changeCourse()

        const getHistoryList = async() => {
            let data = await getHistory()
            if(data.status === 200) {
                hostoryList.value = data.data.data
            }
        }
        getHistoryList()

        return {
            courseShow,
            imgShow,
            hostoryList,
            isMobile
        }
    },
}
</script>
<style lang="scss" scoped>
    .course {
        margin: 30px;
    }
    .list {
        padding-top: 20px;
        position: relative;
        .item {
            .year {
                display: inline-block;
                color: #1369ba;
                font-size: 20px;
                b {
                    font-size: 32px;
                }
            }
            .circle {
                display: inline-block;
                width: 32px;
                height: 32px;
                background: #1369ba;
                border-radius: 50%;
                position: relative;
                z-index: 22;
            }
            .context {
                display: inline-block;
                vertical-align: top;
                margin-top: 5px;
                width: 300px;
                height: 160px;
                padding: 20px 30px;
                
                .img {
                    width: 100%;
                    height: 110px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                p {
                    font-weight: bold;
                    font-size: 18px;
                    margin-top: 5px;
                }
            }
        }
        .first {
            margin-left: calc(50% - 134px);
            .context {
                background: url(../../../assets/images/first.png) no-repeat;
                background-size: 100% 100%;
                padding-left: 50px;
                margin-left: 10px;
            }
            .circle {
                margin-left: 20px;
            }
        }
        .left {
            margin-left: calc(50% - 410px);
            margin-top: -20px;
            .context {
                background: url(../../../assets/images/left.png) no-repeat;
                background-size: 100% 100%;
                padding-right: 50px;
            }
            .circle {
                margin-right: 20px;
                margin-top: 88px;
                margin-left: 15px;
            }
        }
        .right {
            margin-left: calc(50% - 134px);
            margin-top: -20px;
            .context {
                background: url(../../../assets/images/right.png) no-repeat;
                background-size: 100% 100%;
                padding-left: 50px;
                margin-left: 10px;
            }
            .circle {
                margin-left: 20px;
                margin-top: 88px;
            }
        }
        .last {
            margin-left: calc(50% - 410px);
            margin-top: -20px;
            .context {
                background: url(../../../assets/images/last.png) no-repeat;
                background-size: 100% 100%;
                padding-right: 50px;
            }
            .circle {
                margin-right: 20px;
                margin-left: 15px;
                vertical-align: bottom;
            }
            .year {
                vertical-align: bottom;
            }
        }
    }
    .line {
        border-left: 3px dashed #dcdcdc;
        position: absolute;
        left: 50%;
        top: 0;
        height: 102%;
    }

    .course-slide-fade-enter-active {
        transition:  all .6s ease-in;
    }
    .course-slide-fade-enter-from {
        transform: translateY(55px);
        opacity: 0;
    }

    .img-slide-fade-enter-active {
        transition:  all .5s ease-in;
    }
    .img-slide-fade-enter-from{
        transform: translateY(70px);
        opacity: 0;
    }

    .span-slide-fade-enter-active {
        transition:  all .3s ease-in;
    }
    .span-slide-fade-enter-from{
        transform: translateY(30px);
        opacity: 0;
    }

    .b-slide-fade-enter-active {
        transition:  all .3s ease-in;
    }
    .b-slide-fade-enter-from{
        transform: translateY(30px);
        opacity: 0;
    }

    .line-slide-fade-enter-active {
        transition:  all .5s ease-in;
    }
    .line-slide-fade-enter-from{
        transform: translateY(300px);
        opacity: 0;
    }

    .mb-content {
        margin: 0.3rem 0;
        margin-bottom: 2rem;
        .line {
            border-left: 0.04rem dashed #dcdcdc;
        }
        .list {
            .item {
               .circle {
                    width: 0.4rem;
                    height: 0.4rem;
                    margin-left: 0.3rem;
                } 
                .year {
                    b {
                        font-size: 0.5rem;
                    }
                }
                .context {
                    width: calc(50% - 0.3rem);
                    padding: 0.15rem 0.15rem 0.15rem 0.3rem;
                    margin-top: 0.3rem;
                    height: auto;
                    .img {
                        height: 1.6rem;
                    }
                    p {
                        font-size: 0.28rem;
                    }
                }
            }
            .first {
                margin-left: calc(50% - 2.1rem);
            }
            .right {
                margin-left: calc(50% - 2.1rem);
                .circle {
                    margin-top: 2rem;
                }
            }
            .left {
                margin-left: 0.2rem;
                .context {
                    width: calc(50% - 0.8rem);
                    padding: 0.15rem 0.3rem 0.15rem 0.15rem;
                }
                .circle {
                    margin-top: 2rem;
                    margin-left: 0.08rem;
                }
            }
        }
    }
</style>